<script setup lang="ts">
import type { EditorialCardProps } from './EditorialCard.props'
defineProps<EditorialCardProps>()
const { openDialog } = useDialog()
const { ts } = useI18n()
const shopTheLookRef = ref()

const alignOptions = (alignement: 'center' | 'right' | 'left') => {
  if (alignement === 'right') {
    return 'justify-end items-end align-end my-auto'
  } else if (alignement === 'left') {
    return 'justify-start items-start align-middle my-auto'
  } else {
    return 'justify-center content-center text-center items-center'
  }
}

const handleShopTheLookClick = () => {
  if (shopTheLookRef.value.shopTheLookModalIdRef.uniqueShopTheLookModalId) {
    openDialog(
      shopTheLookRef.value.shopTheLookModalIdRef.uniqueShopTheLookModalId
    )
  }
}
</script>

<template>
  <div
    class="editorial-card bg-background-secondary text-text-primary flex flex-col gap-4"
    :class="{
      'gap-6': cardText && cardText.padding === 'm',
      'gap-8': cardText && cardText.padding === 'l',
    }"
  >
    <div class="card-container relative">
      <div @click="zoomableMedia ? openDialog('zoomableImage') : ''">
        <div :class="{ 'card main-media-card': hoverMedia }">
          <EditorialContent
            v-bind="cardMedia"
            ref="shopTheLookRef"
            :bg-desktop-media="cardMedia.bgMedia"
            :bg-mobile-media="cardMedia.bgMedia"
            :desktop-aspect-ratio="desktopAspectRatio ?? '4/5'"
            :mobile-aspect-ratio="mobileAspectRatio ?? '4/5'"
          />
        </div>
        <div
          v-if="hoverMedia"
          class="card hover-image-card image-slider-container absolute top-0 h-full w-full object-cover"
        >
          <UIMedia
            v-bind="hoverMedia"
            class="h-full w-full object-cover object-top"
            :aspect-ratio="desktopAspectRatio ?? '4/5'"
          />
        </div>
      </div>
      <OrganismsModal v-if="zoomableMedia" id="zoomableImage">
        <template #body>
          <UIZoomableImage v-bind="zoomableMedia" :reset-zoom="0" />
        </template>
      </OrganismsModal>
    </div>
    <div class="flex flex-col gap-2">
      <UtilsMarkdown
        v-if="cardText && cardText.title"
        :content="cardText.title"
        is-preprocessed
        container="span"
        :class="[
          alignOptions(cardText?.titleAlign!),
          cardText.size === 's' ? 'text-book-6' : 'text-book-5',
        ]"
      />
      <UtilsMarkdown
        v-if="cardText && cardText.subtitle"
        :content="cardText.subtitle"
        is-preprocessed
        container="span"
        :class="[
          alignOptions(cardText?.subtitleAlign!),
          cardText.size === 's' ? 'text-light-6' : 'text-light-5',
        ]"
      />
      <div v-if="cardText && cardText.linkLabel?.length" class="flex gap-4">
        <UILink
          v-for="(link, index) in cardText.linkLabel.slice(0, 4)"
          :key="`${link.label}-${index}`"
          class="flex shrink-0 text-center"
          :class="[
            alignOptions(cardText.linkLabelAlign!),
            cardText.size === 's' ? '!text-link-7' : '!text-link-6',
          ]"
          v-bind="link"
          anatomy="link"
        />
      </div>
      <UtilsMarkdown
        v-if="cardText && cardText.caption"
        :content="cardText.caption"
        is-preprocessed
        container="span"
        class="text-light-7 text-end"
      />
      <div
        v-if="
          cardMedia.bgMedia &&
          cardMedia.bgMedia[0].shopTheLook &&
          cardText?.shopTheLookText
        "
        class="text-book-6"
      >
        <button
          :aria-label="ts('aria-labels.shopTheLook')"
          class="flex items-center gap-2"
          @click="handleShopTheLookClick"
        >
          {{ cardText.shopTheLookText }}
          <DefaultIconsChevronDown class="h-4 w-4" :aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-container {
  .card {
    transition: opacity 0.4s ease;
  }
  .main-media-card {
    opacity: 1;
  }
  .hover-image-card {
    opacity: 0;
  }
  &:hover {
    .main-media-card {
      opacity: 0;
    }
    .hover-image-card {
      opacity: 1;
    }
  }
}
</style>
